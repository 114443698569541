import React from 'react';
import Image from 'next/legacy/image';
import NextLink from 'next/link';
import {
	Box, Container, Text,
	Link as ChakraLink, Button,
} from '@chakra-ui/react';
import 'react-alice-carousel/lib/alice-carousel.css';
import Page from '../components/Page';
import collectView from '../utils/collectView';
import { serversideInstance as serverAxios } from '../utils/axios';

export default function Home({ views, homepageInfo }) {
	return (
		<Page views={views}>
			<Container w="100%" maxW="100vw" px={0} mx="auto">
				<Box w="100%" color="whiteAlpha.800" px={10}>
					<Text my={4} as="h2" fontSize="2xl" fontWeight="bold" color="white" letterSpacing={1} paddingX={12}>
						{homepageInfo?.title}
					</Text>
					<Text my={4} as="p" color="gray.300" letterSpacing={1} paddingX={12}>
						{homepageInfo?.desc.split('\n').map((i) => (
							<Text>{i}</Text>
						))}
					</Text>
					<Box display="flex" marginY={20} flexWrap="wrap">
						<Box
							display="flex"
							flexDirection="column"
							flexWrap="wrap"
							width={{ base: '98%', md: '48%', lg: '23%' }}
							marginX="1%"
							marginY={{ base: 5, lg: 0 }}
							paddingX={10}
							justifyContent="center"
							alignItems="center"
						>
							<Image width="1080px" height="720px" src="/img/index/街頭藝人在板橋435藝文特區表演花式籃球.jpeg" />
							<Text as="h3" fontSize="xl" fontWeight="bold" color="whiteAlpha.800" align="center" marginY={5} letterSpacing={1}>文化平權</Text>
							<Box h="150px">
								<Text as="p" mb={1} textAlign="justify">
									致力改造藝文環境，藉由優化設施與服務，縮短藝術文化與民眾的距離，翻轉閱讀環境，使人文采風深入區里巷弄，深入新北市民的生活。
								</Text>
							</Box>
							<ChakraLink as={NextLink} href="/graph/equality" textDecoration="none" colorScheme="gray" color="gray.100" my={1}>
								<Button
									size="lg"
									my={5}
									colorScheme="white"
									variant="outline"
									fontSize="lg"
									borderRadius="0px"
									_hover={{
										color: 'pryBlue',
										bgColor: 'white',
									}}
									_active={{
										color: 'pryBlue',
										bgColor: 'white',
									}}
								>
									查看更多
								</Button>
							</ChakraLink>
						</Box>
						<Box
							display="flex"
							flexDirection="column"
							flexWrap="wrap"
							width={{ base: '98%', md: '48%', lg: '23%' }}
							marginX="1%"
							marginY={{ base: 5, lg: 0 }}
							paddingX={10}
							justifyContent="center"
							alignItems="center"
						>
							<Image width="1080px" height="720px" src="/img/index/文化多元-新北市青年參與社造行動.jpg" />
							<Text as="h3" fontSize="xl" fontWeight="bold" color="whiteAlpha.800" align="center" marginY={5} letterSpacing={1}>文化多元</Text>
							<Box h="150px">
								<Text as="p" mb={1} textAlign="justify">
									本市地域幅員遼闊，擁有富饒的地理環境及人文特色，以博物館為中心發散，展現各種跨國交流，實踐兼容並蓄、融合多元文化之精神。
								</Text>
							</Box>
							<ChakraLink as={NextLink} href="/graph/pluralism" textDecoration="none" colorScheme="gray" color="gray.100" my={1}>
								<Button
									size="lg"
									my={5}
									colorScheme="white"
									variant="outline"
									fontSize="lg"
									borderRadius="0px"
									_hover={{
										color: 'pryBlue',
										bgColor: 'white',
									}}
									_active={{
										color: 'pryBlue',
										bgColor: 'white',
									}}
								>
									查看更多
								</Button>
							</ChakraLink>
						</Box>
						<Box
							display="flex"
							flexDirection="column"
							flexWrap="wrap"
							width={{ base: '98%', md: '48%', lg: '23%' }}
							marginX="1%"
							marginY={{ base: 5, lg: 0 }}
							paddingX={10}
							justifyContent="center"
							alignItems="center"
						>
							<Image width="1080px" height="720px" src="/img/index/文化創生-行動巴士美術號進行「ARTGOGO」活動.jpg" />
							<Text as="h3" fontSize="xl" fontWeight="bold" color="whiteAlpha.800" align="center" marginY={5} letterSpacing={1}>文化創生</Text>
							<Box h="150px">
								<Text as="p" mb={1} textAlign="justify">
									為豐富本市的文化底蘊，匯集各方專業人才，同時深耕青年透過社區營造，開創出新能量，培養生活美感，實踐「全民美術館」宗旨。
								</Text>
							</Box>
							<ChakraLink as={NextLink} href="/graph/creation" textDecoration="none" colorScheme="gray" color="gray.100" my={1}>
								<Button
									size="lg"
									my={5}
									colorScheme="white"
									variant="outline"
									fontSize="lg"
									borderRadius="0px"
									_hover={{
										color: 'pryBlue',
										bgColor: 'white',
									}}
									_active={{
										color: 'pryBlue',
										bgColor: 'white',
									}}
								>
									查看更多
								</Button>
							</ChakraLink>
						</Box>
						<Box
							display="flex"
							flexDirection="column"
							flexWrap="wrap"
							width={{ base: '98%', md: '48%', lg: '23%' }}
							marginX="1%"
							marginY={{ base: 5, lg: 0 }}
							paddingX={10}
							justifyContent="center"
							alignItems="center"
						>
							<Image width="1080px" height="720px" src="/img/index/圖二、十三行數位博物館3D檢索系統，可全角度瀏覽文物細部_1.jpg" />
							<Text as="h3" fontSize="xl" fontWeight="bold" color="whiteAlpha.800" align="center" marginY={5} letterSpacing={1}>文化永續</Text>
							<Box h="150px">
								<Text as="p" mb={1} textAlign="justify">
									透過文資修復管理計畫，嶄新再現，跨越界線帶領民眾零距離，親近文化，結合不同領域資源，打造品牌拓展行銷，開啟藝文新世代。
								</Text>
							</Box>
							<ChakraLink as={NextLink} href="/graph/sustainability" textDecoration="none" colorScheme="gray" color="gray.100" my={1}>
								<Button
									size="lg"
									my={5}
									colorScheme="white"
									variant="outline"
									fontSize="lg"
									borderRadius="0px"
									_hover={{
										color: 'pryBlue',
										bgColor: 'white',
									}}
									_active={{
										color: 'pryBlue',
										bgColor: 'white',
									}}
								>
									查看更多
								</Button>
							</ChakraLink>
						</Box>
					</Box>
				</Box>
			</Container>
		</Page>
	);
}

export async function getServerSideProps(ctx) {
	const views = await collectView(ctx);
	const { cookies } = ctx.req;
	const cookieHeaders = (cookies?.token && cookies?.['token.sig']) ? {
		headers: {
			Cookie: `token=${cookies.token}; token.sig=${cookies['token.sig']}`,
		},
	} : {};
	const { data: homepageInfo } = await serverAxios.get('/api/v1/homepageinfos', cookieHeaders);
	return {
		props: {
			views,
			homepageInfo,
		},
	};
}
